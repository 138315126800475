.form-container {
    border: 1px solid rgb(240, 240, 240);
    border-radius: 8px;
    padding: 8px 16px;
    margin: 16px 8px;
}

.header-container h1, h2, h3, h4, h5, h6{
    margin-bottom: 12px;
}

.header-container .description {
    font-size: 14px;
    color: rgb(90, 90, 90);
    margin-top: 0px;
}

.spaced-list li {
    margin-bottom: 8px;;
}