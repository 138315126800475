#emailInviteForm {
    margin-bottom: 8px;
}

#emailInviteForm > *{
    margin-top : 8px;
}

.user-info-container p {
    color: rgb(60, 60, 60);
    font-size: 0.9em;
}