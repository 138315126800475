.MuiListItem-root a.active {
    background-color: #E5F1FC;
}

.MuiListItem-root a.active *{
    color: #40A6FF;
}

.MuiFab-root {
    margin : 0;
    top : 'auto';
    right : 24px;
    bottom : 32px;
    left : 'auto';
    position : 'fixed';
}