body {
  margin: 0;
}

$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 50;

@mixin particle($colors...) {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 60s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(75) - 25) * 1vw (random(75) - 25) * 1vh;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}

.background {
  background: #3E1E68;
  position: relative;

  .accounts {
    @include particle(#11293E, #48BBC1, #1D60AB);
  }

  .contracts {
    @include particle(#64067F, #7522B6, #7342EA);
  }

  .kloud {
    @include particle(#06287F, #226FB6, #42AEEA);
  }

  .audire {
    @include particle(#067F4C, #22B692, #78EA42);
  }

  .kauditor {
    @include particle(#007FFF, #012747, #014A91);
  }

  .text-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vh;
    text-align: left;
    color: white;
    font-family: 'Arial', sans-serif;
    z-index: 1000;

    .product-text {
      font-size: 24px;

      span {
        font-weight: bold;
      }
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.product-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 3s ease-out;
}

.product-visible {
  opacity: 1;
}