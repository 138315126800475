@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#formContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 480px;
    height: 100%;
    max-height: 500px;
    margin-bottom: 16px;
}

#formLogin input,
#formLogin button,
#formLogin .MuiOutlinedInput-root .MuiInputBase-root {
    display: block;
    margin: auto;
    margin-top: 8px;
    width: 100%;
}

#formContainer .MuiTextField-root {
    display: block;
    width: 100%;
}

#formContainer .MuiTextField-root input {
    width: 100%;
}

#formContainer .MuiOutlinedInput-root.MuiInputBase-root {
    width: 100%;
}

.login-content {
    font-family: 'Poppins', sans-serif !important;
}

.fade-container {
    transition: opacity 1.25s ease;
    opacity: 1;
  }
  
  .fade-container.hidden {
    transition: opacity 1.5s ease;
    opacity: 0;
  }