#formContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 480px;
    height: 100%;
    max-height: 500px;
    margin-bottom: 16px;
}