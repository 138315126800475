
.activity-container > * {
    padding: 0;
    padding-left: 16px;
}

.activity-description {
    display: flex;
    flex-direction: 'row';
}

.activity-description {
    padding-top: 24px;
}

.activity-container p{ 
    margin: 0;
    margin-left: 16px;
}

.activity-extra-info-container p {
    margin-top: 4px;
}