@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

a {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ta-c {
  text-align: center;
}

.header-area {
  border-radius: 12px;
  border: 1px solid rgb(235, 235, 235);
  margin-bottom: 24px;
  padding: 16px 24px;
}

.header-area .desc {
  color: rgb(90, 90, 90);
}

hr { background-color: rgb(235, 235, 235); height: 1px; border: 0; }

@media only screen and (max-width: 680px) {
  .lte-sm {
    display: none;
  }
}